import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from './auth.interceptor';

//Redux

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';

import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { acordoReducer } from './modules/client/client.reducer';

import { NgxMaskModule } from 'ngx-mask';

import { AuthGuard } from './guards/auth.service';
import { AppGuard } from './guards/app.service';
import { LoaderService } from './services/navigation/loading.service';
import { LoaderInterceptor } from './services/navigation/loading.interceptor';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { WindowRef } from 'src/app/core/utils/WindowRef';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    StoreModule.forRoot({ acordo: acordoReducer }),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false,
    }),
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthGuard,
    AppGuard,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
