import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecorderControllerService {

  status: boolean = false;
  statusObservable = new Subject<any>();

  constructor() { }

  getStatusObserver(): Observable<any> {
    return this.statusObservable.asObservable();
  }

  setStatus(status: boolean): void {
    this.status = status;
    this.statusObservable.next(status);
  }

  getStatus(): boolean {
    return this.status;
  }
}
