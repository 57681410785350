<section id="not-found">
  <div class="container">
    <div class="col-12">
      <div class="box-mini-shadow">
        <div class="text-center">
          <img
            src="../../../../../assets/logotipos/Itapeva.svg"
            alt="itapeva"
            style="width: 150px"
          />
        </div>
        <br />
        <p class="text-center">
          <b>
            <h1 style="font-size: xxx-large;">404</h1>
          </b>
          <b>Página não encontrada</b>
          <br />
          <br />
          <a class="btn-master buttonload" id="btn-go-back" routerLink="/"
            >Voltar</a
          >
        </p>
      </div>
    </div>
  </div>
</section>
