const steps = [
  {
    rank: 1,
    type: 'consultaCpf',
    desc: 'step 2A - Consultou CPF',
    flowIn: 'Atendido - Conf. ID / Interesse',
    statusIn: 'Consultou CPF',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 1,
    type: 'acessouPaginaInicial',
    desc: 'step 1A - Acessou pagina inicial',
    flowIn: 'Atendido - Nao Conf. ID / Sem Interesse',
    statusIn: 'step 1A - Acessou pagina inicial',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 1,
    type: 'navegouNaPaginaInicial',
    desc: 'step 1B - Navegou na pagina inicial',
    flowIn: 'Atendido - Nao Conf. ID / Sem Interesse',
    statusIn: 'step 1A - Navegou na pagina inicial',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 1,
    type: 'acessouLigacoesIndevidas',
    desc: 'step 1C - Acessou ligaçoes indevidas',
    flowIn: 'Atendido - Nao Conf. ID / Sem Interesse',
    statusIn: 'step 1A - Acessou ligaçoes indevidas',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 1,
    type: 'acessouPaginaDeLogin',
    desc: 'step 1D - Acessou pagina de login',
    flowIn: 'Atendido - Nao Conf. ID / Sem Interesse',
    statusIn: 'step 1A - Acessou pagina de login',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 2,
    type: 'criaSenha',
    desc: 'step 3A - Aceitou termos e criou senha',
    flowIn: 'Atendido - Conf. ID / Interesse',
    statusIn: 'Aceitou termos e criou senha',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 3,
    type: 'atualizaTel',
    desc: 'step 5A - Atualizou o telefone',
    flowIn: 'Atendido - Conf. ID / Interesse',
    statusIn: 'Atualizou o telefone',
    statusOut: null,
    flowOut: null,
  },
  {
    rank: 9,
    type: "cpfNaoRelacionado",
    desc: "step 2C - CPF Não relacionado",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "CPF nao relacionado",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 3,
    type: "esqueciMinhaSenha",
    desc: "step 2G - Esqueci minha senha",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Esqueci minha senha",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 5,
    type: "acessouViaMinhasNegoc",
    desc: "step 6C - Logou pela tela Acessar minhas negociacoes",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Logou pela tela Acessar minhas negociacoes",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 8,
    type: "painelControle",
    desc: "step 7A - Acessou painel oportunidades",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Acessou oportunidades",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 5,
    type: "painelMeusDados",
    desc: "step 7B - Acessou meus dados",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Acessou meus dados",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 8,
    type: "painelCentralDeDuvidas",
    desc: "step 7D - Acessou central de duvidas",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Acessou central de duvidas",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 8,
    type: "negociacaoAVista",
    desc: "step 9A - Clicou em negociar a vista",
    flowIn: "Atendido - Conf. ID / Interesse",
    statusIn: "Clicou em Negociar a Vista",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 9,
    type: "revisao",
    desc: "step 12A - Aceitou os termos da negociação",
    flowIn: "Envio - GOL",
    statusIn: "Aceitou os termos da negociacao",
    statusOut: null,
    flowOut: "Incompleto"
  },
  {
    rank: 10,
    type: 'sucesso',
    desc: 'step 13A - Concluiu a negociação',
    flowIn: 'Envio - GOL',
    statusIn: 'Concluiu a negociacao',
    statusOut: null,
    flowOut: 'Completo',
  },
  {
    rank: 10,
    type: 'falhaAoRegistrarNegociacaoNoPainel',
    desc: 'step 13FA_ITP - Falha ao registrar a negociação no painel',
    flowIn: 'Atendido - Conf. ID / Interesse',
    statusIn: 'Falha ao registrar a negociação no painel',
    statusOut: null,
    flowOut: 'Falha',
  },
  {
    rank: 7,
    type: "erroLogin",
    desc: "step 17B - Problemas no login",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Problemas no login",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 7,
    type: "erroCarregarNegociacoes",
    desc: "step 17C - Problemas ao carregar negociações",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Problemas ao carregar negociações",
    statusOut: null,
    flowOut: null
  },
  {
    rank: 9,
    type: "liContestou",
    desc: "step 16A - Ligações indevidas - Contestou dívida",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Ligacoes Indevidas",
    statusOut: "Contestou Divida",
    flowOut: null
  },
  {
    rank: 9,
    type: "liNaoCpc",
    desc: "step 16B - Ligações indevidas - Não é CPC",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Ligacoes Indevidas",
    statusOut: "Nao e CPC",
    flowOut: null
  },
  {
    rank: 9,
    type: "liNaoReconhece",
    desc: "step 16C - Ligações indevidas - Não reconhece dívida",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Ligacoes Indevidas",
    statusOut: "Nao Reconhece a Divida",
    flowOut: null
  },
  {
    rank: 9,
    type: "liJaPagou",
    desc: "step 16D - Ligações indevidas - Já pagou",
    flowIn: "Atendido - Nao Conf. ID / Sem Interesse",
    statusIn: "Ligacoes Indevidas",
    statusOut: "Ja Pagou",
    flowOut: null
  }
];

export default steps;
