import { createAction, props } from '@ngrx/store';
import { Acordo } from './models/interface/avista';




export const criar = createAction(
    '[ACORDO] criar',
    props<{ acordos: Acordo}>()
);


export const editar = createAction(
    '[CLIENT] editar',
    props<{ vlEntrada: number }>()
);

