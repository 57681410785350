import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DataManagementService } from 'src/app/services/data-management/data-management.service';
import { CommunService } from 'src/app/services/inspectlet/commun.service';

const baseURL = environment.API_URL;
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private dataUser = new BehaviorSubject<any>({});
  public dataUser$ = this.dataUser.asObservable();

  constructor(
    private httpClient: HttpClient,
    private communService: CommunService,
    private dmService: DataManagementService,
  ) {}

  async sigin(cpfCnpj: string, password: string) {
    const cpfCnpjFormatted = cpfCnpj.replace(/\D/g, '');

    const data = {
      cpfCnpj: cpfCnpjFormatted,
      senha: password,
    };

    const response: any = await this.httpClient
      .post(baseURL + `/api/v2/Wauth/Login`, data)
      .pipe(take(1))
      .toPromise()
      .catch((err) => {});

    if (response?.statusCode === 200) {
      localStorage.setItem('token', response?.data?.token);
    }
    return response?.statusCode === 200 ? true : false;
  }

  sigUp() {}

  async logout() {
    const token = localStorage.getItem('token');
    await this.invalidToken(token);
    sessionStorage.clear();
    localStorage.clear();
    this.communService.sessionId.next(null);
    window.location.href = '/';
  }

  async changePassword(password) {
    const { CpfCnpj } = this.getUserFromToken();
    const cpfCnpjFormatted = CpfCnpj.replace(/\D/g, '');
    const execId = this.dmService.getData('execId');

    const data = {
      execResposta: execId,
      cpf: cpfCnpjFormatted,
    };

    return;
  }

  changePasswordLogin(password) {
    const { CpfCnpj } = this.getUserFromToken();
    const cpfCnpjFormatted = CpfCnpj.replace(/\D/g, '');

    const data = {
      cpfCnpj: cpfCnpjFormatted,
      senha: password,
    };

    return this.httpClient.put(baseURL + `/api/v2/Wauth/PassWord/Update`, data);
  }

  async EmailPasswordNotify(cpf: string) {
    const cpfCnpjFormatted = cpf.replace(/\D/g, '');

    const data = {
      CpfCnpj: cpfCnpjFormatted,
    };

    const response: any = await this.httpClient
      .post(baseURL + `/api/v2/Wauth/PassWord/Notificacao`, data)
      .pipe(take(1))
      .toPromise()
      .catch((err) => {});
    return response?.statusCode === 200 ? true : false;
  }

  requestDataPin(cpf: string) {
    const cpfCnpjFormatted = cpf.replace(/\D/g, '');
    return this.httpClient.get(
      `${baseURL}/api/v2/Wauth/UserSetting/DataContato/${cpfCnpjFormatted}`
    );
  }

  requestPinEmailAletraSenha(cpf: string) {
    const cpfCnpjFormatted = cpf.replace(/\D/g, '');

    const data = {
      cpfCnpj: cpfCnpjFormatted,
    };

    return this.httpClient.post(`${baseURL}/api/v2/Wauth/PassWord`, data);
  }

  requestPin(cpf: string, pinsendOptionEmail: boolean = false) {
    const cpfCnpjFormatted = cpf.replace(/\D/g, '');
    let data = {
      cpfCnpj: cpfCnpjFormatted,
      PinSendOption: pinsendOptionEmail ? 'E' : 'F',
    };
    return this.httpClient.post(baseURL + `/api/v2/Wauth/Pin`, data);
  }

  async siginWithAcessPin(codigo: string, cpfCnpj: string = '') {
    const cpfCnpjFormatted = cpfCnpj.replace(/\D/g, '');
    let data: any = {
      codigoAcesso: codigo,
    };

    if (cpfCnpjFormatted) {
      data.CpfCnpj = cpfCnpjFormatted;
    }

    const response: any = await this.httpClient
      .post(baseURL + `/api/v2/Wauth/Login/AuthCode`, data)
      .pipe(take(1))
      .toPromise()
      .catch((err) => {});

    if (response?.statusCode === 200) {
      localStorage.setItem('token', response?.data?.token);
    }
    return response?.statusCode === 200 ? true : false;
  }

  isLogged() {
    const token = window.localStorage.getItem('token');
    if (token) {
      const expirationDate = helper.getTokenExpirationDate(token);
      return expirationDate > new Date();
    } else return false;
  }

  getUserFromToken() {
    const token = window.localStorage.getItem('token');
    const user = helper.decodeToken(token);
    this.dmService.setData('identification', user?.CpfCnpj, true);
    return user;
  }

  dataPinPW(data: any) {
    this.dataUser.next(data);
  }

  async invalidToken(token) {
    const invalidate = await this.httpClient
      .post(`${baseURL}/api/v2/Wauth/RefreshToken/BlackList`, {
        Token: token,
      })
      .toPromise();
    return invalidate;
  }

  async tokenIsInvalid(token) {
    const { CpfCnpj } = this.getUserFromToken();
    const isInvalid: any = await this.httpClient
      .post(`${baseURL}/api/v2/Wauth/RefreshToken/VerificaTokenBlackList`, {
        Token: token,
        cpfCnpj:CpfCnpj,
      })
      .toPromise();
    if (isInvalid?.data) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    }
  }
}
