import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { WindowRef } from 'src/app/core/utils/WindowRef';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmartlookService {
  private urlSmarthLook = `${environment.API_URL}/api/v2/smartlookSession`;
  private smartlookWID = environment.smartlookWID;

  constructor(private httpCliente: HttpClient, private winRef: WindowRef) {}

  async init() {
    this.clearCookies();
    await new Promise((resolve) => setTimeout(resolve, 500));
    console.log('smartlook initing...');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
          window.smartlook||(function(d) {
            var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
            var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
            c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', '${this.smartlookWID}', { region: 'eu' });
            smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
      `;
    document.head.appendChild(script);

    this.monitorLocalStorageVariable('SL_L_23361dd035530_SID', () => {
      this.registrarSessaoNoSmartlook();
    });
  }

  async clearCookies() {
    localStorage.removeItem('SL_L_23361dd035530_VID');
    localStorage.removeItem('SL_L_23361dd035530_KEY');
    localStorage.removeItem('SL_L_23361dd035530_SID');
    this.deleteCookie('SL_C_23361dd035530_KEY');
    this.deleteCookie('SL_C_23361dd035530_VID');
    this.deleteCookie('SL_C_23361dd035530_SID');
  }

  deleteCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  private monitorLocalStorageVariable(key: string, callback: () => void) {
    const intervalId = setInterval(() => {
      const value = localStorage.getItem(key);
      if (value) {
        // A variável está no LocalStorage, dispara o callback
        callback();
        clearInterval(intervalId);
      }
    }, 1000); // Verifica a cada 1 segundo
  }

  private registrarSessaoNoSmartlook() {
    const smartlookVariables = JSON.parse(localStorage.getItem("SL_L_23361dd035530_SID") || '{}')

    const wid = Object.keys(smartlookVariables)[0];
    const sid = smartlookVariables[wid].sessionId
    const vid = smartlookVariables[wid].visitorId

    this.postSmarthLookToServer({
      wid,
      sid,
      oid: '',
      vid,
    });

    console.log('session: ', { sid, wid });
  }

  private postSmarthLookToServer(data: {
    vid: string;
    sid: string;
    wid: string;
    oid: string;
    execId?: string;
  }) {
    const execId =
      sessionStorage.getItem('execId') ||
      sessionStorage.getItem('__tempexec') ||
      '';
    data.execId = execId;
    const smartlook: any = (this.winRef as any).nativeWindow.smartlook;

    smartlook('identify', execId);
    this.httpCliente
      .post(this.urlSmarthLook, data)
      .pipe(take(1))
      .subscribe((res) => {});

    smartlook('identify', execId);
  }
}
