export default function inspectLet(id, callBack, resetSession) {
  var promise = null;

  (function () {
    window.__insp = window.__insp || [];

    __insp.push(["wid", 2129405794]);
    window.__insp.push(["identify", id]);

    var ldinsp = null;
    ldinsp = function () {
      if (typeof window.__inspld != "undefined") return;
      window.__inspld = 1;
      var insp = document.createElement("script");
      insp.type = "text/javascript";
      insp.async = true;
      insp.id = "inspsync";

      insp.src =
        ("https:" == document.location.protocol ? "https" : "http") +
        "://cdn.inspectlet.com/inspectlet.js?wid=2129405794&r=" +
        Math.floor(new Date().getTime() / 3600000);

      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(insp, x);
    };
    setTimeout(ldinsp, 0);

    promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        //console.log(window.__insp);
        // window.__insp.clearSession();
        resolve("OK");
      }, 50);
    });
  })();

  // let timeout;

  // const timeoutCallback = () => {
  //   const sessionId = __insp['sid'];
  //   if (sessionId) {
  //     localStorage.setItem('__insSession', sessionId);
  //     callBack();
  //   } else {
  //     timeout = makeTimeout();
  //   }
  // };

  // const makeTimeout = () => setTimeout(timeoutCallback, 100);
  // // const destroyTimeout = () => clearTimeout(timeout);

  // timeout = makeTimeout();

  promise.then(() => {
    let timeout;

    const timeoutCallback = () => {
      const sessionId = __insp["sid"];
      if (sessionId) {
        sessionStorage.setItem("__insSession", sessionId);
        callBack();
      } else {
        timeout = makeTimeout();
      }
    };

    const makeTimeout = () => setTimeout(timeoutCallback, 100);
    // const destroyTimeout = () => clearTimeout(timeout);

    timeout = makeTimeout();
  });
}
