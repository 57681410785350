import { Action, createReducer, on } from '@ngrx/store';
import { criar, editar } from './client.actios';
import { Acordo } from 'src/app/modules/client/models/interface/avista';







export const initialState: Acordo = new Acordo();

const _acordoReducer = createReducer(
  initialState,
  on(criar, (state, { acordos }) => ({ ...state, acordos })),

  on(editar, (state, { vlEntrada }) => {

    if (vlEntrada !== 0) {
      return {
        ...state,
        vlEntrada: vlEntrada
       
      }
    } else {
      return state;
    }
  }),

);


export function acordoReducer(state: Acordo | undefined, action: Action) {
  return _acordoReducer(state, action);
}