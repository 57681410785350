import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgBrazil } from 'ng-brazil';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxCaptchaModule } from 'ngx-captcha';

import { FaleConoscoComponent } from './components/fale-conosco/fale-conosco.component';
import { ChatModule } from 'src/app/core/components/chat/chat.module';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';

@NgModule({
  declarations: [FaleConoscoComponent, LoadingComponent],
  exports: [FaleConoscoComponent, LoadingComponent],
  imports: [
    CommonModule,
    NgxCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBrazil,
    TextMaskModule,
    ChatModule,
  ],
})
export class CoreModule {}
