
export class Acordo {
    acordoRenegociacao: number;
    grupoContratos: number;
    indexador: number;
    modalidade: number;
    operacao: number;
    taxaJuros: number;
    vlEntrada?: number;

    constructor(vlEntrada?: number){
        this.vlEntrada = vlEntrada;
    }

    // constructor(acordoRenegociacao: number,
    //     grupoContratos: number,
    //     indexador: number,
    //     modalidade: number,
    //     operacao: number,
    //     taxaJuros: number,
    //     vlEntrada: number) {



    //     this.acordoRenegociacao = acordoRenegociacao;
    //     this.grupoContratos = grupoContratos;
    //     this.indexador = indexador;
    //     this.modalidade = modalidade;
    //     this.operacao = operacao;
    //     this.taxaJuros = taxaJuros;
    //     this.vlEntrada = vlEntrada;


    // }
}
