import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import steps from './steps';
import { environment } from 'src/environments/environment';
import { InspectletService } from 'src/app/services/inspectlet/inspectlet.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DataManagementService } from 'src/app/services/data-management/data-management.service';
import { SmartlookService } from '../smartlook/smartlook.service';

const baseURL = environment.API_URL;
const step = steps;

@Injectable({
  providedIn: 'root',
})
export class JourneyTrackerService {
  headers = new HttpHeaders({
    accept: 'application/json',
    'Content-Type': 'application/json',
  });

  engagementAnalysisTool: 'smartlook' | 'inspectlet' = 'smartlook';

  constructor(
    private httpClient: HttpClient,
    private dmService: DataManagementService,
    private authService: AuthenticationService,
    private smartlookService: SmartlookService,
    private inspectletService: InspectletService
  ) {}

  private async createExecHome() {
    const execExist = this.dmService.getData('execId');
    if (execExist) {
      return;
    }
    const cpf = '00000000000';

    const response: any = await this.httpClient
      .post(
        `${baseURL}/api/v2/JourneyTracker/criarExec`,
        { cpfCnpj: cpf },
        { headers: this.headers }
      )
      .toPromise();

    const excecId = response?.execId;
    sessionStorage.setItem('execId', excecId);

    this.insert('00000000000', 'acessouPaginaInicial');

    // Configuracao da ferramenta de analise de engajamento
    if (this.engagementAnalysisTool == 'smartlook') {
      this.smartlookService.init();
    } else {
      this.inspectletService.init();
      setTimeout(() => {
        const __insSession = sessionStorage.getItem('__insSession');
        if (!__insSession) {
          this.smartlookService.init();
        }
      }, 10000);
    }
  }

  async createExecAuthenticated() {
    const execExist = sessionStorage.getItem('execId');
    if (execExist) {
      return;
    }
    const { CpfCnpj } = this.authService.getUserFromToken() || {};

    if (!CpfCnpj) {
      this.createExecHome();
      return;
    }

    const cpfCnpjFormatted = CpfCnpj.replace(/\D/g, '');

    const response: any = await this.httpClient
      .post(
        `${baseURL}/api/v2/JourneyTracker/criarExec`,
        { cpfCnpj: cpfCnpjFormatted },
        { headers: this.headers }
      )
      .toPromise();

    sessionStorage.setItem('execId', response?.execId);

    this.vincularExec(cpfCnpjFormatted);
    // Configuracao da ferramenta de analise de engajamento
    if (this.engagementAnalysisTool == 'smartlook') {
      this.smartlookService.init();
    } else {
      this.inspectletService.init();
      setTimeout(() => {
        const __insSession = sessionStorage.getItem('__insSession');
        if (!__insSession) {
          this.smartlookService.init();
        }
      }, 10000);
    }
  }

  async vincularExec(cpfCnpj: string) {
    const cpfCnpjFormatted = cpfCnpj.replace(/\D/g, '');
    const execID = sessionStorage.getItem('execId');
    const data = {
      cpfCnpj: cpfCnpjFormatted,
      execId: execID,
    };

    await this.httpClient
      .post(`${baseURL}/api/v2/JourneyTracker/VincularExec`, data)
      .toPromise();
  }

  async insert(
    cpfCnpj: string,
    step: string,
    idNegociacao?: string,
    metadata?: object,
    rest: any = { rest: {} }
  ) {
    const cpfCnpjFormatted = cpfCnpj
      ? cpfCnpj.replace(/\D/g, '')
      : this.dmService.getData('identification');

    const filteredStep = steps.filter((item) => item.type == step);
    const target = filteredStep ? filteredStep[0] : null;
    let data: any = {
      cpfCnpj: cpfCnpjFormatted,
      priority: target ? target['rank'] : null,
      step: target ? target['desc'] : null,
      flowIn: target?.flowIn,
      statusIn: target?.statusIn,
      statusOut: target?.statusOut,
      flowOut: target?.flowOut,
      idNegociacao: null,
      metadata: metadata,
      execId: this.dmService.getData('execId'),
    };

    data = { ...data, ...rest };

    const response: any = await this.httpClient
      .post(`${baseURL}/api/v2/JourneyTracker/Insert`, data)
      .toPromise();
  }
}
