import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { execInspectLet } from 'src/app/shared/inspectlet/storage';
import { CommunService } from './commun.service';
import { RecorderControllerService } from './recorder-controller.service';
import { WindowRef } from 'src/app/core/utils/WindowRef';

@Injectable({
  providedIn: 'root',
})
export class InspectletService {
  wId = '2129405794';
  execID = '';
  loggedCpf: string;

  statusObservable: Subscription;
  recorderStatus: boolean;

  constructor(
    private router: Router,
    private winRef: WindowRef,
    private communService: CommunService,
    private recorderControllerService: RecorderControllerService
  ) {
    this.statusObservable = this.recorderControllerService
      .getStatusObserver()
      .subscribe((resp) => {
        this.recorderStatus = resp;
      });
  }

  async init() {
    // delay of 2000 ms
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.starting();
  }

  async starting() {
    const execExistent = sessionStorage.getItem('execId');

    if (execExistent) {
      this.loggedCpf = localStorage.getItem('identification') || '00000000000';
      this.communService.vinculaWSession(
        this.loggedCpf || '00000000000',
        execExistent
      );
      this.iniciaGravacao(execExistent, true);
    }
  }

  vincularCpfComIdExec(cpf: string, idExec: string) {
    this.loggedCpf = localStorage.getItem('identification') || '00000000000';
    this.communService.vinculaWSession(
      this.loggedCpf || '00000000000',
      this.execID
    );
  }

  iniciaGravacao(idExec: string, resetSession?: boolean) {
    this.loggedCpf = localStorage.getItem('identification') || '00000000000';

    if (this.loggedCpf) {
      this.communService.vinculaWSession(this.loggedCpf, idExec);
    }

    this.recorderControllerService.setStatus(true);
    this.getStoreId(idExec, resetSession)
      .then((result: string) => {
        this.communService.sessionId.next(result);
        const sessionId = sessionStorage.getItem('__insSession');
        this.communService.postInspectToServer(this.wId, sessionId, idExec);
      })
      .catch((err) => {
        console.log(err);
      });

    if (resetSession) {
      const __insp: any = (this.winRef as any).nativeWindow.__insp;
      __insp.push(['resetSession']);
    }
  }

  async getStoreId(idExec: string, resetSession: boolean) {
    return await new Promise((resolve, reject) => {
      const callback = () => {
        const exist = sessionStorage.getItem('__insSession');

        if (exist) resolve(exist);
        else reject(new Error('Não ha dados gravado'));
      };
      // Inicia a gravação do inspectLect sem CPF
      execInspectLet(idExec, callback, resetSession);
    });
  }
}
