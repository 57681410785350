import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

/**
 * Com esse serviço é possível manusear dados durante a navegação do usuário
 * sempre que um desses dados é alterado, o serviço notifica todos os
 * observadores que estão escutando o evento de alteração.
 */

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {

  data: any = {}
  private dataObserver = new Subject<any>()

  constructor() { }

  getData(key?: string) {
    if (key) {
      if (this.data[key]) {
        return this.data[key];
      } else {

        const restoredItem = sessionStorage.getItem(key);
        if (restoredItem !== 'undefined')
          return JSON.parse(restoredItem);
      }
    } else {
      return this.data;
    }
  }

  getDataObserver() {
    return this.dataObserver.asObservable();
  }

  setData(key: string, value: string | object | [] | number | void, persist: boolean = false) {
    this.data[key] = value;
    this.dataObserver.next(this.data);
    if (persist) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  removeData(key: string, removeFromSessionStorage: boolean = false) {
    if(removeFromSessionStorage){
      sessionStorage.removeItem(key);
    }
    delete this.data[key];
    this.dataObserver.next(this.data);
  }

  dataClean() {
    this.data = undefined;
    this.dataObserver.next();
  }
}
