import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/home/pages/not-found/not-found.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'client', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },
  { path: 'negotiation', loadChildren: () => import('./modules/negotiation/negotiation.module').then(m => m.NegotiationModule) },

  { path: 'testes', loadChildren: () => import('./testes/testes.module').then(m => m.TestesModule) },

  //Login
  { path: 'forgot-pw', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  // { path: 'pin-for-pw', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  // { path: 'request-code', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },

  //Área logada
  // { path: 'client-options', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },
  { path: 'client-accept', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },
  // { path: 'client-success', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },

  { path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
