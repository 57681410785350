import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loading.service';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  interceptableRequests: string[] = [
    '/v2/Wauth/UserSetting/Exists',
    '/api/v2/Wauth/Login',
    '/api/v2/Itapeva/Cliente/Divida',
    '/api/v2/Itapeva/ConsultarUsuario',
    '/api/v2/Wauth/PassWord/Update',
    '/api/v2/Itapeva/Acordo/Negociacao',
    '/api/v2/Wauth/KBA/Match',
    '/api/v2/Wauth/Pin/ClickToCall',
    '/api/v2/Wauth/UserSetting/UpdatePhone',
    '/api/v2/Wauth/UserSetting/UpdateEmail',
    '/api/v2/Wauth/UserSetting/DataContato',
    '/api/v2/Wauth/PreRegistration',
    '/api/v2/Wauth/UserSetting/DataContato',
    '/api/v2/Itapeva/IntegrationDriver/users/create',
    '/api/v2/Itapeva/IntegrationDriver/ConsultarUsuario',
    '/api/v2/AcordosItapeva/negociacoes',
    '/api/v2/Itapeva/Cliente/Renegociacao',
    'api/v2/AcordosItapeva/negociacoes/setStatus',
    '/api/v2/Itapeva/Acordo/renegociacao',
    '/api/v2/AcordosItapeva/negociacoes/registrar'
  ];

  constructor(public loaderService: LoaderService) {}

  queue: string[] = [];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let intercept: boolean = false;

    this.interceptableRequests.forEach((route) => {
      if (req?.url?.includes(route)) {
        intercept = true;
      }
    });

    if (intercept) {
      this.queue.push(req.url);
      this.loaderService.show();
      return next.handle(req).pipe(
        finalize(() => {
          const index = this.queue.indexOf(req.url);
          this.queue.splice(index, 1);
          if (this.queue.length === 0) {
            this.loaderService.hide();
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
