import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DiasAtrasoPipe } from './pipes/dias-atraso.pipe';
import { LogotipoPipe } from './pipes/logotipo.pipe';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { JsonParsePipe } from './pipes/json-parse.pipe';
import { HideEmailPipe } from './pipes/hide-email.pipe';
import { HidePhonePipe } from './pipes/hide-phone.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DiasAtrasoPipe,
    LogotipoPipe,
    CpfCnpjPipe,
    JsonParsePipe,
    HideEmailPipe,
    HidePhonePipe
  ],
  exports: [
    DiasAtrasoPipe,
    LogotipoPipe,
    CpfCnpjPipe,
    JsonParsePipe,
    HideEmailPipe,
    HidePhonePipe
  ],
})
export class SharedModule {}
