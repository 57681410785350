import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommunService {
  urlInspectLet = `${environment.API_URL}/api/v2/inspectletSession`;
  urlSmarthLook = `${environment.API_URL}/api/v1/smartlook_session`;
  vincularExecWSession = `${environment.API_URL}/api/v2/JourneyTracker/VincularExec`;
  urlInspectLog = `${environment.API_URL}/api/v1/InspectLog`;

  sessionId = new Subject<any>();

  headers = new HttpHeaders({
    accept: 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(private httpCliente: HttpClient) {}

  getIsnpectSessionId(): Observable<any> {
    return this.sessionId.asObservable();
  }

  postInspectToServer(wId: string, sId: string, execId: string) {
    const data = {
      websiteId: wId,
      sessionId: sId,
      execId: execId,
    };

    this.httpCliente
      .post(this.urlInspectLet, data)
      .pipe(take(1))
      .subscribe((res) => {});
  }

  postSmarthLookToServer(vId: string, sId: string, execId: string) {
    const data = {
      vid: vId,
      sid: sId,
      execId: execId,
    };

    this.httpCliente
      .post(this.urlSmarthLook, data)
      .pipe(take(1))
      .subscribe((res) => {});
  }

  vinculaWSession(cpfCnpj: string, execId: string) {
    const data = {
      cpfCnpj: cpfCnpj,
      execId: execId,
    };

    if (!(execId?.length > 0)) {
      return;
    }

    this.httpCliente
      .post(this.vincularExecWSession, data)
      .pipe(take(1))
      .subscribe((res) => {});
  }

}
